import React, {useEffect, useState} from 'react';
import {graphql} from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/layout";
import {DataProvider} from "../contentful/data-provider";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import {GatsbyImage} from "gatsby-plugin-image";
import TeamMembersSection from "../components/sections/team-members-section";
import ColumnsWithIconSection from "../components/sections/columns-with-icons-section";
import TwoColumnSectionWithCta from "../components/sections/two-column-section-with-cta";

const TeamMember = ({data}) => {
    const [selectedTab, setSelectedTab] = useState(typeof window !== "undefined" ? parseInt(localStorage.getItem("gwSelectedTeamMemberTab")) : 0);

    const dataProvider = new DataProvider(data);
    const teamMember = data.contentfulTeamMemberElement
    let metaDescription = ''
    try {
        metaDescription = teamMember.metaDescription ?? renderRichText(teamMember.body)[0]?.props.children[0]?.substring(0, 200) + '...';
    } catch (e) {
        console.error(e);
    }

    return (
        <Layout dataProvider={dataProvider}>
            <Seo
                dataProvider={dataProvider} title={`${teamMember.name} | ${teamMember.title} | GlassView`}
                description={metaDescription}
            />
            <main className={"pt-24 lg:pt-24"}>
                <section className="flex justify-center !pb-0">
                    <div className={'px-10 max-w-[769px]'}>
                        <div className={'flex flex-col md:flex-row gap-[24px] md:gap-[40px] md:justify-start md:items-center'}>
                            <div className={''}>
                                <div className={'image-container'}>
                                    <GatsbyImage
                                        className={'rounded-full w-[100px] h-[100px] md:w-[160px] md:h-[160px]'}
                                        image={teamMember.image.gatsbyImageData}
                                        alt={`Image of ${teamMember.name}, ${teamMember.title} of GlassView`}
                                    />
                                </div>
                            </div>
                            <div className={''}>
                                <h1 className={'text-[32px] md:text-[48px] leading-[62px] tracking-[-0.02em] text-black font-bold'}>{teamMember.name}</h1>
                                <div className={'text-[14px] md:text-[16px] leading-[1em]'}>{teamMember.title}</div>
                                {(teamMember.xUrl || teamMember.linkedInUrl) &&
                                    <div className={`flex gap-[8px] mt-[16px]`}>
                                        {teamMember.xUrl &&
                                            <a className={`w-[36px] h-[36px] rounded-full border flex items-center justify-center border-[#EBEBEB]`} href={teamMember.xUrl} target={'_blank'}>
                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_177_4669)">
                                                        <path d="M7.1428 5.08175L11.6108 0H10.5524L6.6712 4.41151L3.5736 0H0L4.6852 6.67162L0 12H1.0584L5.1544 7.34026L8.4264 12H12M1.4404 0.780947H3.0664L10.5516 11.2574H8.9252" fill="black"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_177_4669">
                                                            <rect width="12" height="12" fill="white"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </a>
                                        }
                                        {teamMember.linkedInUrl &&
                                            <a className={`w-[36px] h-[36px] rounded-full border flex items-center justify-center border-[#EBEBEB]`} href={teamMember.linkedInUrl} target={'_blank'}>
                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.1117 0H0.885937C0.396094 0 0 0.386719 0 0.864844V11.1328C0 11.6109 0.396094 12 0.885937 12H11.1117C11.6016 12 12 11.6109 12 11.1352V0.864844C12 0.386719 11.6016 0 11.1117 0ZM3.56016 10.2258H1.77891V4.49766H3.56016V10.2258ZM2.66953 3.71719C2.09766 3.71719 1.63594 3.25547 1.63594 2.68594C1.63594 2.11641 2.09766 1.65469 2.66953 1.65469C3.23906 1.65469 3.70078 2.11641 3.70078 2.68594C3.70078 3.25312 3.23906 3.71719 2.66953 3.71719ZM10.2258 10.2258H8.44687V7.44141C8.44687 6.77813 8.43516 5.92266 7.52109 5.92266C6.59531 5.92266 6.45469 6.64688 6.45469 7.39453V10.2258H4.67813V4.49766H6.38437V5.28047H6.40781C6.64453 4.83047 7.22578 4.35469 8.09063 4.35469C9.89297 4.35469 10.2258 5.54063 10.2258 7.08281V10.2258V10.2258Z" fill="black"/>
                                                </svg>
                                            </a>
                                        }
                                    </div>
                                }
                            </div>
                        </div>


                        <div className={'flex mt-[48px]'}>
                            <div className={'basis-full text-[16px] leading-[28px] tracking-[-0.023em] team-member-single-body'}>
                                {renderRichText(teamMember.body)}
                            </div>
                        </div>
                    </div>
                </section>
                <TeamMembersSection
                    content={data.contentfulTeamMembersSection}
                    enableTitle={false}
                    selectedTab={selectedTab}
                />
                <ColumnsWithIconSection content={data.contentfulColumnsWithIconSection}/>
                <TwoColumnSectionWithCta content={data.contentfulTwoColumnSectionWithCta}/>
            </main>
        </Layout>
    );
};

export default TeamMember;

export const query = graphql`
    query TeamMemberBySlug($slug: String!, $locale: String) {
        contentfulTeamMemberElement(slug: { eq: $slug }, node_locale: { eq: $locale }) {
            id
            title
            name
            body {
                raw
            }
            image {
                gatsbyImageData
            }
            xUrl
            linkedInUrl
        }
        allContentfulMenu(filter: { node_locale: { eq: $locale } }) {
            edges {
                node {
                    ...Menus
                }
            }
        }
        contentfulTeamMembersSection(contentful_id: {eq: "71yXz1ZbgUby2QLOJ9I6Rf"}) {
            ...AllEntryDetails
        }
        contentfulColumnsWithIconSection(contentful_id: {eq: "1ojWuftkhczvPxkSdPtkc9"}) {
            ...AllEntryDetails
        }
        contentfulTwoColumnSectionWithCta(contentful_id: {eq: "2JCSP3R0WHEIYpePSYhqhj"}) {
            ...AllEntryDetails
        }
    }
`;
